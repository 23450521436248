import { Metadata } from '@redwoodjs/web'

import PageHeader from 'src/components/PageHeader/PageHeader'
import IntegrationsCell from 'src/components/SuperAdmin/Integrations/IntegrationsCell'

const IntegrationsPage = () => {
  return (
    <>
      <Metadata
        title="All Client Integrations"
        description="All Client Integrations"
      />
      <PageHeader title="All Client Integrations" />
      <IntegrationsCell />
    </>
  )
}

export default IntegrationsPage
